import { RequestParameters } from 'relay-runtime/lib/util/RelayConcreteNode'
import { Variables } from 'relay-runtime/lib/util/RelayRuntimeTypes'
import { getUrl } from '@/constant'

export const fetchGraphQL = async (
  request: RequestParameters,
  variables: Variables
): Promise<any> => {
  const response = await fetch(getUrl(), {
    method: 'POST',
    credentials: 'include', // Necessary to send auth cookies
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      query: request.text,
      variables,
    }),
  })

  const data = await response.json()

  return data
}
