import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getPageFromUrl } from '@/routes'
import { TRACKING_ENABLED } from '@/constant'

const NextPageTracking = () => {
  const router = useRouter()
  const handleRouteChange = (url: string) => {
    // have this here as needed to form url
    // we never use the base url
    const page = getPageFromUrl(new URL(url, 'https://www.mylifetime.co.nz').pathname)
    if (TRACKING_ENABLED) {
      global.analytics.page(page)
    }
  }
  useEffect(() => {
    handleRouteChange(router.pathname)
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return null
}
export default NextPageTracking
