export default {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: 'bold', // Normally, it is "semibold"
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    md: {
      fontSize: 'sm',
    },
  },
  // 3. We can add a new visual variant
  variants: {
    // "with-shadow": {
    //   bg: "red.400",
    //   boxShadow: "0 0 2px 2px #efdfde",
    // },
    // // 4. We can override existing variants
    // solid: (props) => ({
    //   bg: props.colorMode === "dark" ? "red.300" : "red.500",
    // }),
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
}
