export default {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  grey: {
    50: '#f9f9f9',
    100: '#D7D7D7',
    300: '#757575',
    400: '#4d4d4d',
    900: '#212121',
  },
  red: {
    50: '#FFF4F6',
    400: '#DF3550',
  },
  blue: {
    100: '#EFF6FE',
    200: '#D9E3ED',
    400: '#C9E2FA',
    500: '#0B8CDB',
    800: '#005587',
    900: '#004b78',
  },
  orange: {
    900: '#DE9629',
  },
  yellow: {
    400: '#FFEA30',
  },
  green: {
    50: '#ECF4ED',
    400: '#48AD51',
  },
  teal: {
    400: '#00877F',
  },
}
