import { AppProps } from 'next/app'

import { ChakraProvider, Container } from 'ui-components'
import { Global } from '@emotion/react'

import NextPageTracking from '@/NextPageTracking'
import theme from '../theme'
import fonts from '../styles/font-face'
import { RelayEnvironmentProvider } from 'react-relay'
import createEnvironment from '../relay/createEnvironment'
import 'react-datepicker/dist/react-datepicker.css'
import '../components/Forms/date-picker.css'
import '../styles/ios-fix.css'

import { init } from '@/sentry'

init()

const App = ({ children }: { children: React.ReactNode }) => {
  const Environment = createEnvironment()
  return <RelayEnvironmentProvider environment={Environment}>{children}</RelayEnvironmentProvider>
}

function MyLifetimeContainer({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={fonts} />
      <Container flex={1} display="flex" flexDirection="column" maxW="2xl" p={0} bgColor="grey.50">
        <App>
          <NextPageTracking />
          <Component {...pageProps}></Component>
        </App>
      </Container>
    </ChakraProvider>
  )
}

export default MyLifetimeContainer
